$default-grid-columns: 12;
$grid-gutter-xl: 120px;
$grid-gutter-md: 40px;
$grid-gutter-base: 12px;
$grid-gutter-sm: 20px;

:root {
    --grid-gutter-x: #{$grid-gutter-base};
    --grid-gutter-y: #{$grid-gutter-base};
}

.grid {
    $grid: &;

    display: grid;
    grid-gap: $grid-gutter-base * 2;
    grid-template-columns: 1fr 1fr;

    > * {
        margin-bottom: 0 !important;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--grid-gutter-x) * -1);
    margin-left: calc(var(--grid-gutter-x) * -1);

    & > *:not(.container) {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: var(--grid-gutter-x);
        padding-left: var(--grid-gutter-x);
        margin-bottom: calc(var(--grid-gutter-y) * 2);
    }

    &:last-child {
        //--grid-gutter-y: 0;
    }

    &--spaced {
        justify-content: space-between;
    }

    &--reverse {
        flex-direction: column-reverse;
    }

    &--align {
        &-left {
            align-items: flex-start;
        }

        &-center {
            align-items: center;
        }

        &-right {
            align-content: flex-end;
        }
    }

    &--justify {
        &-left {
            justify-content: flex-start;
        }

        &-center {
            justify-content: center;
        }

        &-right {
            justify-content: flex-end;
        }

        &-evenly {
            justify-content: space-evenly;
        }
    }
}

.col {
    flex: 1 0 0%;

    &--auto {
        flex: 0 0 auto;
        width: auto;
    }

    &--align {
        &-center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &--justify {
        &-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &--space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    @for $i from 1 through $default-grid-columns {
        &--#{$i} {
            @include grid-col($col: $i, $grid-columns: $default-grid-columns, $width: true);
        }
    }

    &--sm {
        max-width: 440px;
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .col {
        @for $i from 1 through $default-grid-columns {
            &--#{$i}--lg {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $width: true);
            }

            &--auto--lg {
                flex: 0 0 auto;
                width: auto;
            }
        }
    }
}


@media only screen and (max-width: map-get($breakpoints, md)) {
    .col {
        @for $i from 1 through $default-grid-columns {
            &--#{$i}--md {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $width: true);
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    :root {
        --grid-gutter-x: #{$grid-gutter-sm};
        --grid-gutter-y: #{$grid-gutter-sm};
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .col {
        @for $i from 1 through $default-grid-columns {
            &--#{$i}--sm {
                @include grid-col($col: $i, $grid-columns: $default-grid-columns, $width: true);
            }
        }
    }

    .row {
        &--mobile {
            &-reverse {
                flex-direction: column-reverse;
            }
        }
    }
}
