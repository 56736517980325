.flex {
    &--reverse {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .flex {
        &-tablet {
            &--reverse {
                flex-direction: column-reverse;
            }

            &--row-reverse {
                flex-direction: row-reverse;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .flex {
        &-mobile {
            &--reverse {
                flex-direction: column-reverse;
            }

            &--row-reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
