$container-widths: (
    xs: 485px,
    sm: 630px,
    md: 820px,
    lg: 1000px,
    xl: 1160px
);

.container {
    width: 100%;
    padding-right: var(--grid-gutter-x);
    padding-left: var(--grid-gutter-x);
    margin-right: auto;
    margin-left: auto;
    max-width: map-get($container-widths, xl);

    &--xl {
        max-width: map-get($container-widths, xl);
    }

    &--lg {
        max-width: map-get($container-widths, lg);
    }

    &--md {
        max-width: map-get($container-widths, md);
    }

    &--sm {
        max-width: map-get($container-widths, sm);
    }

    &--xs {
        max-width: map-get($container-widths, xs);
    }

    &--fluid {
        width: 100%;
        max-width: none;
    }

    &--align {
        &-left {
            margin-right: auto;
            margin-left: 0;
        }

        &-right {
            margin-right: 0;
            margin-left: auto;
        }
    }
}
