@each $color-key, $color-value in $colors {
    @each $tone-key, $tone-value in $color-value {
        @if $tone-key == base {
            .color#{'--' + $color-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key} {
                background-color: $tone-value !important;
            }
        } @else {
            .color#{'--' + $color-key + '-' + $tone-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key + '-' + $tone-key} {
                background-color: $tone-value !important;
            }
        }
    }
}

:root {
    @each $color-key, $color-value in $colors {
        @each $tone-key, $tone-value in $color-value {
            @if $tone-key == base {
                --color#{'-' + $color-key}: #{$tone-value};
            } @else {
                --color#{'-' + $color-key + '-' + $tone-key}: #{$tone-value};
            }
        }
    }
}

.gradient {
    &--linear {
    }
}
