.footer {
    $footer: &;

    width: 100%;
    padding: spacing(3xl) 0;
    position: relative;

    &__container {
        display: flex;
    }

    &__group {
        display: flex;
        flex-direction: column;
        flex: 1;

        &:last-child {
            flex-grow: 1.5;
        }

        &--evenly {
            justify-content: space-evenly;
        }
    }

    &__contact {
        margin-block: auto;
    }

    &__copy {
        font-size: $font-size-xs;
    }

    .menu {
        gap: spacing(2xs);

        &__item {
            min-height: 0;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .footer {
        $footer: &;
        padding: spacing(lg) 0;
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .footer {
        border-top: $border-width-base solid $border-color-base;
        $footer: &;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .footer {
        padding: spacing(xl) 0;

        &__container {
            display: flex;
            flex-direction: column;
            gap: spacing(xl);
        }
    }
}
