.hr {
    width: 100%;
    height: 0;
    margin: spacing(xl) auto;
    border: none;
    border-top: $border-width-base solid $border-color-base;
    display: block;
    box-sizing: content-box;

    &--faded {
        opacity: .5;
    }

    &--md {
        max-width: 30%;
        margin: spacing(xl) auto;
    }

    &--sm {
        max-width: 100px;
        margin: spacing(3xl) auto;
    }

    &--xs {
        max-width: 40px;
        margin: spacing(md) 0;
    }

    &--dashed {
        border-top-style: dashed;
    }

    &--no-margin {
        margin: 0;
    }

    &--align {
        &-left {
            margin-left: 0;
        }
    }
}

hr {
    @extend .hr;
}
