.content {
    .section {
        &--offset {
            &-top {
                margin-top: 200px !important;
            }

            &-bottom {
                margin-bottom: 200px !important;
            }
        }

        &__offset {
            &-elem {
                &--top {
                    margin-top: -200px;
                }

                &--bottom {
                    margin-bottom: -200px;
                }
            }
        }
    }
}
