$form-field-spacing-base: spacing(base);
$form-field-spacing-sm: spacing(xs);

$form-disabled-opacity: .25;

$form-input-bg-color-grey: color(grey, 3);

input[type=checkbox],
input[type=radio] {
    border: none;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form {
    $form: &;

    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;

    &__fieldset {
        margin-bottom: spacing(md);
    }

    &__section {
        padding: spacing(xl) 0;
        border-bottom: $border-width-base solid color(grey, 7);

        &:first-child:not(&--clear-top) {
            border-top: $border-width-base solid color(grey, 7);
        }

        &:last-child {
            border-bottom: none
        }

        &--clear {
            padding: 0;
            border: none;

            &-top {
                padding-top: 0;
                border-top: none;
            }

            &-bottom {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &--short {
            padding: spacing(md) 0;
            border-width: $border-width-base;
        }
    }

    &__footer {
        padding-top: spacing(xs);
    }

    &--full {
        width: 100%;
    }

    &__info {
        color: color(grey);
        margin-top: spacing(base);
    }

    &--grey {
        color: color(grey);

        #{$form} {
            &__input {
                background-color: $form-input-bg-color-grey;

                & + #{$form}__input-group-bg {
                    background-color: $form-input-bg-color-grey;
                }
            }
        }

        .selectize-input {
            &.input-active {
                background-color: color(white) !important;
            }
        }
    }

    &__group {
        &--border-right {
            border-right: $border-width-base solid color(grey, 7);
        }

        &--spaced {
            padding: 0 spacing(3xl);
        }

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__groups {
        display: flex;

        #{$form} {
            &__group {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .form {
        &__section {
            padding: spacing(md) 0;

            &--tablet {
                &--clear {
                    padding: 0;
                    border: none;

                    &-top {
                        padding-top: 0;
                        border-top: none;
                    }

                    &-bottom {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
        &__groups {
            flex-direction: column;
        }

        &__group {
            &--spaced {
                padding: spacing(md) 0 0 0;
            }

            &--border-right {
                border-right: none;
                border-bottom: $border-width-base solid color(grey, 7);
                padding-bottom: spacing(md);
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .form {
        &__section {
            &--mobile {
                &--clear {
                    padding: 0;
                    border: none;

                    &-top {
                        padding-top: 0;
                        border-top: none;
                    }

                    &-bottom {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
