$mask-height-1: 60px;
$mask-height-2: 25px;

.section-mask {
    margin: $mask-height-1 auto;
    background: color(white);
    position: relative;

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: $mask-height-1;
        background-image: url(#{$images-folder}section-mask-top.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom center;
        position: absolute;
        z-index: -1;
        top: -$mask-height-1 + 2px;
    }

    &:after {
        background-image: url(#{$images-folder}section-mask-bottom.svg);
        top: auto;
        bottom: -$mask-height-1 + 2px;
        background-position: top center;
    }

    &--2 {
        &:before,
        &:after {
            height: $mask-height-2;
            background-image: url(#{$images-folder}section-mask-top-2.svg);
            top: -$mask-height-2 + 2px;
        }

        &:after {
            background-image: url(#{$images-folder}section-mask-bottom-2.svg);
            top: auto;
            bottom: -$mask-height-2 + 2px;
            background-position: top center;
        }
    }
}
