@use 'sass:math';

$list-disc-size: 7px;
$list-box-size: 45px;

ul, ol {
    li {
        margin-bottom: spacing(xs);
    }
}

.list {
    &--disc {
        li {
            display: flex;
            align-items: center;
            margin-bottom: spacing(xs);

            &:before {
                content: '';
                display: inline-flex;
                align-self: flex-start;
                width: $list-disc-size;
                min-width: $list-disc-size;
                height: $list-disc-size;
                min-height: $list-disc-size;
                border-radius: $list-disc-size;
                background-color: transparent;
                border: $border-width-base solid color(grey, 6);
                margin-right: spacing(sm);
                margin-top: calc(.5em + #{math.div($list-disc-size, 2)} - #{$border-width-base * 2});
            }
        }
    }

    &--comma {
        li {
            &:after {
                content: ',';
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

ul {
    @extend .list--disc;
}

ol {
    &.list {
        counter-reset: ol-counter;

        li {
            counter-increment: ol-counter;

            &:before {
                content: counter(ol-counter);
                line-height: 1;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .list {
    }
}
