$icons: (
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    Close,
    Facebook,
    Instagram
);

@each $icon-key, $screen in $iconSizes {
    @if $icon-key == base {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon {
                    width: $icon-value;
                    min-width: $icon-value;
                    height: $icon-value;
                    min-height: $icon-value;
                    font-size: $icon-value;
                }
            }
        }
    } @else {
        @each $screen-key, $icon-value in $screen {
            @if $screen-key == desktop {
                .icon.icon--#{$icon-key} {
                    width: $icon-value;
                    min-width: $icon-value;
                    height: $icon-value;
                    min-height: $icon-value;
                    font-size: $icon-value;
                }
            }
        }
    }
}

@each $icon-key, $icon in $icons {
    .icon-#{$icon-key} {
        mask-image: url(#{$sprite}#icon-#{$icon-key}-view);
        -webkit-mask-image: url(#{$sprite}#icon-#{$icon-key}-view);
    }
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: currentColor;
    fill: currentColor;
    stroke: none;
    vertical-align: middle;
    position: relative;

    &--fill {
        fill: currentColor;
        stroke: none;
    }

    &--stroke {
        fill: none;
        stroke: currentColor;
    }

    &--clear {
        stroke: none;
        fill: currentColor;
    }

    &-mask {
        background-color: currentColor;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    @each $icon-key, $screen in $iconSizes {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == tablet {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    @each $icon-key, $screen in $iconSizes {
        @if $icon-key == base {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        } @else {
            @each $screen-key, $icon-value in $screen {
                @if $screen-key == mobile {
                    .icon--#{$icon-key} {
                        width: $icon-value;
                        height: $icon-value;
                        min-width: $icon-value;
                        min-height: $icon-value;
                    }
                }
            }
        }
    }
}
