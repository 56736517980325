.hero {
    $hero: &;

    display: flex;
    width: 100%;
    min-height: 700px;
    position: relative;

    &__container {
        display: flex;
        padding-top: var(--header-height);
        width: 100%;
        height: 100%;
    }

    &__heading {
        margin-bottom: spacing(lg);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 3;
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        img {
            width: 100%;
        }
    }

    &--retreat {
        #{$hero} {
            &__content {
                height: auto;
                margin-top: auto;
                margin-bottom: spacing(4xl);
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 300px;
            position: absolute;
            bottom: - spacing(sm);
            left: 0;
            z-index: -1;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FBF5F1 100%);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .hero {

    }
}
@media only screen and (max-width: map-get($breakpoints, md)) {
    .hero {

    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .hero {
        min-height: 500px;

        &__heading {
        }
    }
}
