$teaser-mask-height: 60px;

.teaser {
    margin: $teaser-mask-height auto;
    padding: spacing(3xl) 0;
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .teaser {
    }
}
