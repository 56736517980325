.team-member {
    border-bottom: $border-width-base solid $border-color-base;
    margin-bottom: spacing(4xl);

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    &__image {
        display: flex;
        align-items: center;
        flex-direction: column;

        .image {
            max-width: 520px;
            max-height: 520px;

            img {
                border-radius: 100vh;
                overflow: hidden;
            }
        }
    }

    &__heading {
        text-align: center;
        margin-bottom: - spacing(2xl);
        font-size: $font-size-ft;
    }

    &__row {
        margin-bottom: spacing(2xl);

        .row {
            gap: spacing(lg);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .team-member {
        &__row {
            margin-bottom: spacing(base);

            .row {
                gap: 0;

                > .col {
                    margin-bottom: 0;
                }
            }

            .image {
                margin: spacing(base) auto;
            }
        }
    }
}
