@use 'sass:math';

$header-container-height-base: 100px;
$header-navigation-trigger-size: 44px;

$header-overlay-trigger-size: 34px;
$overlay-trigger-icon-width: 24px;
$overlay-trigger-icon-height: 13.5px;
$overlay-trigger-icon-weight: $border-width-base;

:root {
    --header-height: #{$header-container-height-base};
    --overlay-trigger-size: #{$header-overlay-trigger-size};
}

body.overlay-opened {
    .header {
        pointer-events: none;
    }
}

.header {
    $header: &;

    display: flex;
    width: 100%;
    min-height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(3px);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 100%);

    &__container {
        width: 100%;
        min-height: var(--header-height);
        padding: 0 spacing(md);
    }

    &__content {
        width: 100%;
        min-height: var(--header-height);
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        > * {
            flex: 1;
        }
    }

    &__logo {
    }

    &__menu {
        justify-content: center;
        height: 100%;

        &--right {
            justify-content: flex-end;
        }
    }

    &-overlay-trigger {
        $overlay-trigger: &;

        display: none;
        align-items: center;
        justify-content: center;
        width: $header-overlay-trigger-size;
        height: $header-overlay-trigger-size;
        margin-left: auto;
        pointer-events: auto;
        position: fixed;
        z-index: 9999;
        right: var(--grid-gutter-x);
        top: calc((var(--header-height) / 2) - (var(--overlay-trigger-size) / 2));
        transition: all 320ms ease-in-out;

        &__icon {
            $overlay-trigger-icon: &;

            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            width: $overlay-trigger-icon-width;
            height: $overlay-trigger-icon-height;
            color: currentColor;
            pointer-events: none;

            &-top,
            &-middle,
            &-bottom {
                display: block;
                width: inherit;
                height: $overlay-trigger-icon-weight;
                border-radius: $overlay-trigger-icon-weight;
                background: currentColor;
                transform: translateY(0) translateZ(0);
                transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
                transform-origin: center;
            }

            &.active {
                #{$overlay-trigger-icon} {
                    &-top {
                        transform: rotate(-45deg) translateY(math.div($overlay-trigger-icon-height, 4) + ($overlay-trigger-icon-weight * 2)) translateX(- math.div($overlay-trigger-icon-height, 4));
                    }

                    &-middle {
                        opacity: 0;
                    }

                    &-bottom {
                        transform: rotate(45deg) translateY(- math.div($overlay-trigger-icon-height, 4) - ($overlay-trigger-icon-weight * 2)) translateX(- math.div($overlay-trigger-icon-height, 4));
                    }
                }
            }
        }

        &.active {
            #{$overlay-trigger} {
                color: color(white);
                pointer-events: auto;
            }
        }
    }

    .icon {
        display: flex;
        color: inherit;
    }

    &.sticked {
        #{$header} {
            &__container {
            }
        }
    }
}

body {
    &.navigation-overlay-opened {
        .header {
            pointer-events: none;

            &-overlay-trigger {
                color: color(white);
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .header {
        $header: &;

        &__container {
            #{$header}__menu {
                display: none;
            }
        }

        &-overlay-trigger {
            display: inline-flex;
        }
    }
}
