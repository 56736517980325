$fade-movement: 50px;
$fade-skew: 3deg;
$fade-opacity-duration: 800ms;
$fade-transform-duration: 600ms;

$animationDirections: (
    up, right, down, left
);

.hardware-accelerate {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
}

[data-scroll-animation="fade"] {
    opacity: 0;
    transition: $fade-opacity-duration opacity ease-in-out, $fade-transform-duration transform ease-in-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, opacity;
    @extend .hardware-accelerate;

    &.will {
        &-fade-up {
            transform: translateY($fade-movement) skewY(-$fade-skew);
        }

        &-fade-down {
            transform: translateY(-$fade-movement) skewY($fade-skew);
        }

        &-fade-right {
            transform: translateX($fade-movement) skewX(-$fade-skew);
        }

        &-fade-left {
            transform: translateX(-$fade-movement) skewX($fade-skew);
        }

        &-fade-still {
            transition: $fade-opacity-duration opacity ease-in-out;
        }
    }

    &.up,
    &.right,
    &.down,
    &.left {
        opacity: 1 !important;
        transform: none !important;
    }

    &.still {
        opacity: 1 !important;
    }

    &.in {
        opacity: 1 !important;
    }
}

[data-scroll-animation="imageReveal"] {
    overflow: hidden;

    img {
        opacity: 0;
        transform: scale(1.1);
        transition: $fade-opacity-duration opacity ease-in-out, $fade-transform-duration transform ease-in-out;
        transform-style: preserve-3d;
        will-change: transform, opacity;
    }

    &.image-reveal {
        img {
            transform: scale(1);
            opacity: 1;
            position: relative;
            top: -1px;
        }
    }
}

[data-scroll-animation="reveal"] {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    box-shadow: none;
    transition: box-shadow 200ms ease-in-out;

    .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: inline-block;
        background-color: var(--background-color);
        will-change: transform;
    }

    &.box-shadow {
        box-shadow: 10px 15px 60px rgba(color(black), .05);
    }

    &.reveal--grey-lightest {
        .mask {
            background-color: color(grey, 200);
        }
    }
}

[data-scroll-animation="parallax"] {
    position: relative;
    z-index: 10;
    transition: transform .3s ease .0s, $fade-opacity-duration opacity ease-in-out;
    will-change: transform;
}

[data-scroll-animation="parallaxContainer"] {
    .parallax-item {
        @extend .hardware-accelerate;
        transition: $fade-opacity-duration opacity ease-in-out;
        //transition: transform .6s ease .0s, $fade-opacity-duration opacity ease-in-out;
        will-change: opacity;
        position: relative;
        z-index: 2;
    }
}

.lazy-load-image-background.opacity {
    background-image: none !important;
    opacity: 0;
    transition: opacity $fade-opacity-duration ease-in-out !important;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
    opacity: 1;
    transition: opacity $fade-opacity-duration ease-in-out !important;
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
        height: 160px;
    }
    40% {
        transform: translateY(-50px);
        height: 130px;
    }
    60% {
        transform: translateY(-35px);
        height: 135px;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    [data-scroll-animation="parallaxContainer"] {
        .parallax-item {
            transition: $fade-opacity-duration opacity ease-in-out;
        }
    }
}
