$overlay-max-width: 780px;
$overlay-content-padding: calc(var(--header-overlay-trigger-size) + (spacing(md) * 2)) spacing(2xl) spacing(lg) spacing(2xl);
$overlay-bg-color: rgba(color(grey, 6), .95);

body {
    .navigation-overlay {
        $overlay: &;

        @extend .overlay;
        width: 100%;
        max-width: $overlay-max-width;
        height: 100%;
        max-height: none;
        top: 0;
        left: auto;
        right: -100%;
        z-index: 20;

        &__content {
            @extend .overlay__content;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: $overlay-content-padding;
            background: transparent;
            overflow: scroll;

            .dropdown {
                &__toggle {
                    &:after {
                        @extend .icon-ChevronDown;
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                        min-height: 24px;
                        margin-left: spacing(xs);
                    }
                }

                &__content {
                    width: 100%;
                    height: auto;
                    max-height: 0;
                    transition: all ease-in-out 400ms;
                    position: static;
                    background: transparent;
                    padding: 0;
                    margin: 0;

                    .menu {
                        &__link {
                            font-family: $font-family-body;
                            font-size: $font-size-sm;
                            text-transform: uppercase;
                        }
                    }
                }

                &__container {
                    padding: 0;
                }

                &.toggled {
                    > .dropdown__content {
                        max-height: 100vh;
                        transition: max-height ease-in-out 400ms;
                        position: static;
                        background: transparent;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        &__background {
            @extend .overlay__background;
            max-width: $overlay-max-width;
            background: $overlay-bg-color;
        }

        &__menu-item {
            opacity: 0;

            .menu {
                &__link,
                {
                    @extend h4;
                    display: flex;
                    align-items: center;
                    text-transform: none;
                    letter-spacing: initial;
                    margin-bottom: 0;
                    color: color(white);
                }
            }
        }

        &.hidden {
            display: flex !important;
            opacity: 0;
            transition: all 250ms;
            pointer-events: none;
            right: -100%;
        }
    }
}
