$linkedin-color: #0159AA;
$twitter-color: #00BBF5;
$facebook-color: #2461FF;

.share {
    display: flex;
    align-items: center;
    padding: spacing(md) 0;

    &__title {
        margin: 0 spacing(sm) 0 0;
        font-weight: $font-weight-bold;
    }

    &__list {
        display: flex;
        margin: 0;
    }

    &__item {
        padding: 0 spacing(xs);
    }

    &__link {
        color: color(social);
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .share {
        &__title {
            margin-right: auto;
        }
    }
}
