$menu-item-height-base: 40px;

$menu-font-size-base: $font-size-sm;
$menu-font-size-weight: $font-weight-semi-bold;

$menu-icon-spacing-base: spacing(xs);
$menu-horizontal-spacing-base: spacing(sm);
$menu-horizontal-spacing-sm: spacing(sm);

$menu-link-color-base: color(purple, 2);
$menu-link-color-hover: color(orange);
$menu-link-color-active: color(orange);

.menu {
    $menu: &;

    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    font-family: $font-family-body;
    gap: spacing(lg);

    &--vertical {
        flex-direction: column;
        gap: 0;

        #{$menu}__item {
            margin-right: 0;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        min-height: $menu-item-height-base;
        margin-bottom: 0;
        font-size: $menu-font-size-base;
        font-weight: $menu-font-size-weight;
        text-decoration: none;
        text-transform: uppercase;
        transition: $transition-base;
        color: $menu-link-color-base;

        &:before {
            display: none;
        }

        &--icon {
            #{$menu}__link {
                > .icon {
                    margin-left: 0;
                }
            }
        }

        &:hover,
        &--hover,
        &:focus,
        &:active {
            color: $menu-link-color-active;
        }

        &.toggled,
        &--active {
            color: $menu-link-color-active;

            #{$menu}__link {
                min-height: 0;

                &:after {
                    content: '';
                    width: 70%;
                    height: $border-width-base;
                    display: block;
                    background-color: currentColor;
                    margin-bottom: -$border-width-base;
                    position: relative;
                    bottom: -5px;
                }
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: currentColor;
        position: relative;
        font-size: inherit;
        font-weight: $font-weight-semi-bold;
        font-style: normal;
        line-height: $line-height-base;
        letter-spacing: $letter-spacing-base;
        height: inherit;
        min-height: inherit;
        text-decoration: none;

        > .icon {
            color: currentColor;
        }
    }

    &--full {
        #{$menu}__item,
        #{$menu}__link {
            width: 100%;
        }
    }

    &--gap {
        &-sm {
            gap: spacing(xs)
        }
    }
}

@media only screen and (max-width: 1160px) {
    .menu {
        &__item {
            font-size: $font-size-xs;
            letter-spacing: $letter-spacing-sm;
        }
    }
}
