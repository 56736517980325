.text {
    $text: &;

    display: block;
    margin-bottom: $vertical-spacing-base;
    font-weight: $font-weight-regular;

    &--sm {
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
    }

    &--bold {
        font-weight: $font-weight-medium;
    }

    &--lead {
        font-size: $font-size-md;
        line-height: $line-height-md;
    }

    &--underlined {
        text-decoration: underline;
    }
}

.caption {
    display: block;
    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    font-family: $font-family-body;
    font-style: normal;
    letter-spacing: $letter-spacing-lg;
    line-height: $line-height-xs;
    margin-bottom: spacing(base);

    &--sm {
        font-size: $font-size-xs;
    }
}

p, small, em, i, strong {
    line-height: $line-height-md;

    a {
        font-size: inherit;
    }
}

small {
    @extend .text--sm;
}

strong, b {
    @extend .text--bold;
}

a {
    cursor: pointer;
    transition: $transition-base;
    font-weight: $font-weight-regular;
    font-size: $font-size-lg;
    text-decoration: none;
    color: $link-color-base;
}

p, ul, ol {
    margin-bottom: $vertical-spacing-base;
}

p {
    @extend .text;

    a {
        font-style: inherit;
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .heading;
}

h1 {
    @extend .heading--1;
}

h2 {
    @extend .heading--2;
}

h3 {
    @extend .heading--3;
}

h4 {
    @extend .heading--4;
}

h5 {
    @extend .heading--5;
}

h6 {
    @extend .heading--6;
}

em, i {
    font-style: italic;
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .caption {
        line-height: $line-height-base;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .text {
        &-mobile {
            &--sm {
                font-size: $font-size-sm;
                font-weight: $font-weight-regular;
            }
        }
    }
}


@media only screen and (max-width: map-get($breakpoints, md)) {
    .text {
        &-tablet {
            &--sm {
                font-size: $font-size-sm;
                font-weight: $font-weight-regular;
            }
        }
    }
}
