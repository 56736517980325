html {
    scroll-behavior: smooth;
}

body {
    display: flex;
    background-color: var(--background-color);
}

.body {
    &--fixed {
        position: fixed;
        width: 100%;
    }

    &--loading {
        overflow: hidden;
    }
}
