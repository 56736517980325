.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    &.single {
        padding-top: calc(var(--header-height) + 100px);
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .page {
        &.single {
            padding-top: calc(var(--header-height) + 10px);
        }
    }
}
