.position {
    &--relative {
        position: relative !important;
    }

    &--absolute {
        position: absolute !important;
    }

    &--fixed {
        position: fixed;
    }

    &--static {
        position: static;
    }
}
