.schedule {
    &__day {
        padding-right: spacing(lg);
        margin-bottom: spacing(3xl);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .schedule {
        &__day {
            padding-right: 0;
            margin-bottom: spacing(xl);
        }
    }
}
