.content {
    width: 100%;
    padding-top: var(--header-height-base);
    margin: 0 auto 0 0;
    position: relative;

    &--max-width {
        max-width: map-get($breakpoints, hg);
    }

    &__wrapper {
        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .content {
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .content {
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .content {
    }
}
