//$fonts: (
//    avenir: (
//        400: (
//            name: 'AvenirNextLTPro-Regular',
//            weight: 400,
//            style: normal
//        ),
//        400 i: (
//            name: 'AvenirNextLTPro-It',
//            weight: 400,
//            style: italic
//        ),
//        600: (
//            name: 'AvenirNextLTPro-Demi',
//            weight: 500,
//            style: normal
//        ),
//        600 i: (
//            name: 'AvenirNextLTPro-DemiIt',
//            weight: 500,
//            style: italic
//        )
//    )
//);

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

// Family
$font-family-primary: 'Montserrat', sans-serif;
$font-family-body: 'Montserrat', sans-serif;

// Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$font-style-italic: italic;

// Spacing
$letter-spacing-sm: 0.01em;
$letter-spacing-base: 0.02em;
$letter-spacing-md: 0.05em;
$letter-spacing-lg: 0.25em;

// Sizes
$font-size-xs-px: 12px;
$font-size-sm-px: 14px;
$font-size-base-px: 16px;
$font-size-md-px: 18px;
$font-size-lg-px: 20px;
$font-size-xl-px: 24px;
$font-size-ft-px: 30px;
$font-size-2xl-px: 38px;
$font-size-3xl-px: 36px;

$font-size-base-px: $font-size-base-px;

$font-size-xs: calculateRem($font-size-base-px, $font-size-xs-px);
$font-size-sm: calculateRem($font-size-base-px, $font-size-sm-px);
$font-size-base: calculateRem($font-size-base-px, $font-size-base-px);
$font-size-md: calculateRem($font-size-base-px, $font-size-md-px);
$font-size-lg: calculateRem($font-size-base-px, $font-size-lg-px);
$font-size-xl: calculateRem($font-size-base-px, $font-size-xl-px);
$font-size-ft: calculateRem($font-size-base-px, $font-size-ft-px);
$font-size-2xl: calculateRem($font-size-base-px, $font-size-2xl-px);
$font-size-3xl: calculateRem($font-size-base-px, $font-size-3xl-px);

// Line Height
$line-height-xs: 1.1em;
$line-height-sm: 1.4em;
$line-height-base: 1.5em;
$line-height-md: 1.7em;
$line-height-lg: 2.1em;
$line-height-xl: 2.2em;

// Color
$font-color-base: color(purple, 2);

body {
    font-family: $font-family-body;
    font-weight: $font-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    font-size: $font-size-base-px;
    font-weight: $font-weight-regular;
    line-height: $line-height-base;
    color: $font-color-base;
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    html, body {
        font-size: $font-size-base-px;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    html, body {
        font-size: $font-size-base-px;
    }
}

