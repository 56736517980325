html {
    .transform {
        &--uppercase {
            text-transform: uppercase;
        }

        &--lowercase {
            text-transform: lowercase;
        }
    }
}
