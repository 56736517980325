.box {
    display: flex;
    flex-direction: column;
    padding: spacing(xl) spacing(lg);
    background-color: color(white);
    margin-bottom: spacing(md);

    &--full {
        width: 100%;
        height: 100%;
    }

    &--centered {
        align-items: center;
        justify-content: center;
    }

    &--spaced {
        padding: spacing(3xl) spacing(xl);
    }

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
