@use 'sass:math';

$video-play-button-size-base: 88px;

$video-play-button-icon-width-base: 59px;
$video-play-button-icon-height-base: 50px;

video {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.video {
    position: relative;

    &__play-button {
        width: $video-play-button-size-base;
        height: $video-play-button-size-base;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        appearance: none;
        border: none;
        background: none;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: calc(50% - #{math.div($video-play-button-size-base, 2)});
        left: calc(50% - #{math.div($video-play-button-size-base, 2)});
        z-index: 1;

        &-icon.icon {
            width: $video-play-button-icon-width-base;
            height: $video-play-button-icon-height-base;
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: color(white);
            opacity: .32;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            &:before {
                opacity: .44;
            }
        }
    }
}
