@use 'sass:math';

$button-side-padding-base: 36px;
$button-transition-base: all 200ms ease-in-out;
$button-letter-spacing-base: $letter-spacing-md;
$button-font-size-base: $font-size-sm;
$button-font-weight-base: $font-weight-semi-bold;
$button-border-width-base: $border-width-base;
$button-border-radius-base: 0;
$button-height-base: 50px;

$button-primary-bg-initial: color(orange);
$button-primary-bg-hover: color(orange);
$button-primary-bg-disabled: color(orange);
$button-primary-border-color-initial: color(orange);
$button-primary-border-color-hover: color(orange);
$button-primary-border-color-disabled: color(orange);
$button-primary-color-initial: color(white);
$button-primary-color-hover: color(white);
$button-primary-color-disabled: color(white);

$button-secondary-bg-initial: transparent;
$button-secondary-bg-hover: color(orange);
$button-secondary-bg-disabled: transparent;
$button-secondary-border-color-initial: color(orange);
$button-secondary-border-color-hover: color(orange);
$button-secondary-border-color-disabled: color(orange);
$button-secondary-color-initial: color(orange);
$button-secondary-color-hover: color(white);
$button-secondary-color-disabled: color(orange);

$button-tertiary-bg-initial: transparent;
$button-tertiary-bg-hover: color(purple, 2);
$button-tertiary-bg-disabled: transparent;
$button-tertiary-border-color-initial: color(white);
$button-tertiary-border-color-hover: color(purple, 2);
$button-tertiary-border-color-disabled: color(white);
$button-tertiary-color-initial: color(white);
$button-tertiary-color-hover: color(white);
$button-tertiary-color-disabled: color(white);
$button-tertiary-icon-color-initial: color(purple);
$button-tertiary-icon-color-hover: color(purple);
$button-tertiary-icon-color-disabled: currentColor;

$button-link-bg-initial: transparent;
$button-link-bg-hover: transparent;
$button-link-border-color-initial: transparent;
$button-link-border-color-hover: transparent;
$button-link-color-initial: color(purple, 2);
$button-link-color-hover: color(purple, 2);

:root {
    --button-height-base: #{$button-height-base};
}

.button {
    $root: &;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: auto;
    min-height: $button-height-base;
    height: auto;
    min-width: $button-height-base - $button-side-padding-base * 2;
    line-height: $line-height-base;
    padding: 0 $button-side-padding-base;
    position: relative;
    transition: $button-transition-base;
    letter-spacing: $button-letter-spacing-base;
    cursor: pointer;
    font-style: normal;
    font-family: $font-family-body;
    font-size: $button-font-size-base;
    font-weight: $button-font-weight-base;
    appearance: none;
    border-radius: $button-border-radius-base;
    text-decoration: none;
    text-transform: uppercase;
    border-width: $button-border-width-base;
    border-style: solid;

    // Primary Button Style
    background: $button-primary-bg-initial;
    color: $button-primary-color-initial;
    border-color: $button-primary-border-color-initial;

    &:hover,
    &.hover {
        background-color: $button-primary-bg-hover;
        color: $button-primary-color-hover;
        border-color: $button-primary-border-color-hover;
    }

    &--disabled,
    &.disabled,
    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        background: $button-primary-bg-disabled;
        border-color: $button-primary-border-color-disabled;
        color: $button-primary-color-disabled;
        opacity: .3;
    }

    &--full {
        display: flex;
        width: 100%;
        text-align: center;
    }

    > .icon {
        color: currentColor;
        margin-left: - spacing(2xs);
        margin-right: spacing(xs);
    }

    &__text {
        & + .icon {
            margin-left: spacing(xs);
            margin-right: 0;
        }

        & + #{$root}__text {
            margin-left: spacing(xs);
        }
    }

    &--secondary {
        background-color: $button-secondary-bg-initial;
        color: $button-secondary-color-initial;
        border-color: $button-secondary-border-color-initial;

        &:hover,
        &.hover {
            background-color: $button-secondary-bg-hover;
            color: $button-secondary-color-hover;
            border-color: $button-secondary-border-color-hover;
        }

        &--disabled,
        &.disabled,
        &:disabled {
            background: $button-secondary-bg-disabled;
            border-color: $button-secondary-border-color-disabled;
            color: $button-secondary-color-disabled;
        }
    }

    &--tertiary {
        background-color: $button-tertiary-bg-initial;
        color: $button-tertiary-color-initial;
        border-color: $button-tertiary-border-color-initial;

        .icon {
            color: $button-tertiary-icon-color-initial;
        }

        &:hover,
        &.hover {
            background-color: $button-tertiary-bg-hover;
            color: $button-tertiary-color-hover;
            border-color: $button-tertiary-border-color-hover;
        }

        &--disabled,
        &.disabled,
        &:disabled {
            background: $button-tertiary-bg-disabled;
            border-color: $button-tertiary-border-color-disabled;
            color: $button-tertiary-color-disabled;

            .icon {
                color: $button-tertiary-icon-color-disabled;
            }
        }

        &#{$root}--white {
            color: color(white);

            #{$root}__text {
                border-color: color(white);
            }

            .icon {
                color: currentColor;
            }
        }
    }
}

.buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: spacing(base);

    &--align-left {
        justify-content: flex-start;
    }

    &--spaced {
        gap: spacing(xl);
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .button {
        &-tablet {
            &--full {
                display: flex;
                width: 100%;
                text-align: center;
            }
        }
    }

    .buttons {
        flex-direction: column;

        &-mobile {
            &--reverse {
                flex-direction: column-reverse;
            }
        }
    }
}


@media only screen and (max-width: map-get($breakpoints, sm)) {
    .button {
        &-mobile {
            &--full {
                display: flex;
                width: 100%;
                text-align: center;
            }
        }
    }

    .buttons {
        &-mobile {
            &--reverse {
                flex-direction: column-reverse;
            }
        }
    }
}
