.rooms {
    &__list {
        margin-bottom: spacing(2xl);
    }

    &__item {
        margin-bottom: spacing(2xl);
        border-bottom: $border-width-base solid $border-color-base;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}
