$highlight-icon-size-base: 120px;
$highlight-icon-size-plain: 50px;

.highlight {
    $highlight: &;

    &__cards {
        //display: grid;
        //grid-template-columns: repeat(3, 1fr);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: spacing(md);
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $highlight-icon-size-base;
        height: $highlight-icon-size-base;
        margin-bottom: spacing(md);
        background-color: color(white);
        border-radius: 50%;
    }

    &__card {
        display: flex;
        box-sizing: border-box;
        padding: 0 spacing(base);
        margin-bottom: spacing(base);
        flex-direction: column;
        align-items: center;
        text-align: center;

        &--plain {
            #{$highlight} {
                background-color: transparent;

                &__icon {
                    width: $highlight-icon-size-plain;
                    height: $highlight-icon-size-plain;
                    border-radius: 0;
                    background: transparent;
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .highlight {
    }
}
