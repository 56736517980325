@use 'sass:math';

$overlay-content-padding: spacing(xl);
$overlay-bg-color: color(purple, 2);

$overlay-width-base: 846px;
$overlay-offset-base: spacing(xl);

.overlay {
    $overlay: &;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: $overlay-width-base;
    height: calc(100vh - #{$overlay-offset-base * 2});
    max-width: 100%;
    position: fixed;
    top: $overlay-offset-base;
    left: calc(50% - #{math.div($overlay-width-base, 2)});
    right: 0;
    bottom: 0;
    z-index: 31;
    opacity: 1;
    backface-visibility: hidden;
    overflow: hidden;
    transition: all 240ms ease-in-out;

    &--light {
        #{$overlay} {
            &__content {
                background-color: color(white);
                color: color(purple, 2);
            }

            &__close {
                color: color(purple, 2);
            }
        }
    }

    &__inner-wrapper {
        width: 100%;
        height: auto;
        overflow: scroll;
    }

    &__header {
        display: flex;
        margin-bottom: spacing(xl);

        > * {
            margin-bottom: 0;
        }
    }

    &__title {
        padding: 0 0 $overlay-content-padding 0;
        margin-bottom: 0;
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        overflow: auto;
        padding: $overlay-content-padding;
        background: $overlay-bg-color;
        position: relative;
    }

    &__background {
        display: block;
        width: 100%;
        height: 100%;
        background: $overlay-bg-color;
        position: fixed;
        top: 0;
        z-index: -1;
    }

    &__close {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: color(white);
        font-weight: $font-weight-bold;

        .icon {
            margin-left: spacing(2xs);
        }

        &--full {
            width: 100%;
            height: auto;
            padding: spacing(xs) 0;
            justify-content: center;
        }

        &--absolute {
            position: absolute;
            top: spacing(sm);
            right: spacing(sm);
        }
    }

    &.hidden {
        display: flex !important;
        opacity: 0;
        transition: all 250ms;
        pointer-events: none;
    }
}

body {
    .overlay__backdrop {
        content: '';
        display: block !important;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        opacity: 0;
        transition: all 200ms ease-in-out;
        pointer-events: none;
    }

    &.overlay-opened {
        overflow: hidden;

        .overlay__backdrop {
            opacity: 1;
            pointer-events: initial;
            user-select: none;
            background: rgba(color(purple, 2), .25);
        }
    }
}

@media only screen and (max-width: $overlay-width-base) {
    .overlay {
        width: 100%;
        height: 100%;
        max-height: none;
        top: 0;
        left: 0;
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .overlay {
        &__content {
            padding: spacing(md) spacing(sm);
        }
    }
}
