$logo-width-emblem-base: 100px;
$logo-height-emblem-base: 100px;

$logo-width-emblem-lg: 120px;
$logo-height-emblem-lg: 120px;

.site-logo {
    display: block;
    
    img {
        max-width: $logo-width-emblem-base;
        max-height: $logo-height-emblem-base;
    }

    &--lg {
        img {
            max-width: $logo-width-emblem-lg;
            max-height: $logo-height-emblem-lg;
        }
    }

    &--auto {
        width: auto;
        height: auto;
    }
}
