$colors: (
    black: (
        base: black
    ),
    white: (
        base: white
    ),
    grey: (
        base: #C4C4C4,
        1: white,
        2: #F6F6F6,
        3: #E5E5E5,
        4: #C4C4C4,
        5: #9A9A9A,
        6: #333333,
        7: black
    ),
    purple: (
        base: #9E00FF,
        2: #220732
    ),
    orange: (
        base: #FF5C00,
        2: #FBF5F1
    ),
    red: (
        base: red
    ),
    amber: (
        base: yellow
    ),
    green: (
        base: green
    ),
    social: (
        base: #54B400,
        facebook: #2461FF,
        twitter: #00BBF5,
        linkedin: #0159AA
    )
);

@function color($color, $tone: 'base') {
    @return map-get(map_get($colors, $color), $tone);
}

body {
    color: color(purple, 2);
}

$iconSizes: (
    xs: (
        desktop: 16px,
        tablet: 16px,
        mobile: 16px
    ),
    sm: (
        desktop: 20px,
        tablet: 20px,
        mobile: 20px
    ),
    base: (
        desktop: 24px,
        tablet: 24px,
        mobile: 24px
    ),
    md: (
        desktop: 50px,
        tablet: 50px,
        mobile: 50px
    ),
    lg: (
        desktop: 78px,
        tablet: 78px,
        mobile: 78px
    ),
    xl: (
        desktop: 2rem,
        tablet: 2rem,
        mobile: 2rem
    ),
    2xl: (
        desktop: 4rem,
        tablet: 4rem,
        mobile: 4rem
    ),
    3xl: (
        desktop: 5rem,
        tablet: 5rem,
        mobile: 5rem
    ),
    4xl: (
        desktop: 6.25rem,
        tablet: 6.25rem,
        mobile: 6.25rem
    )
);

@function iconSize($size, $platform: 'desktop') {
    @return map-get(map_get($iconSizes, $size), $platform);
}

$spacings: (
    2xs: 8px,
    xs: 12px,
    sm: 16px,
    base: 20px,
    md: 24px,
    lg: 32px,
    xl: 40px,
    2xl: 48px,
    3xl: 56px,
    4xl: 80px
);

@function spacing($size: 'base') {
    @return map-get($spacings, $size);
}

$vertical-spacing-base: spacing(md);

$breakpoints: (
    xs: 1px,
    sm: 576px,
    md: 768px,
    lg: 1024px, //992
    xl: 1200px,
    hg: 1560px
);

:root {
    @each $breakpoint-key, $breakpoint in $breakpoints {
        --breakpoint-#{$breakpoint-key}: #{$breakpoint};
    }
}

$directions: (
    top, left, right, bottom
);

$bottom-frame-height-base: 400px;

$border-width-base: 1px;
$border-width-bold: 2px;
$border-width-big: 4px;

$border-radius-xs: 4px;
$border-radius-sm: 5px;
$border-radius-base: 6px;
$border-radius-md: 8px;
$border-radius-lg: 10px;
$border-radius-xl: 12px;

$border-color-base: rgba(color(grey, 6), .2);
$border-color-darker: rgba(color(purple, 2), .2);

$link-color-base: color(orange);

$box-shadow-base: 0 1px 2px 0 rgba(color(purple, 2), .15);
$box-shadow-medium: 0 10px 20px 0 rgba(color(purple, 2), .2);
$box-shadow-large: 0 4px 8px 0 rgba(color(purple, 2), .2);
$box-shadow-huge: 0 8px 16px 0 rgba(color(purple, 2), .2);

$transition-base: 0.15s all ease-in-out;
$transition-fast: 0.15s all ease-in-out;

:root {
    --background-color: #{color(orange, 2)};
    --primary-color: #{color(purple, 2)};
    --secondary-color: #{color(purple)};
    --base-transition-timing: 400ms;
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
}
