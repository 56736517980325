.form {
    $form: &;

    &__field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: $form-field-spacing-base;

        > * {
            &#{$form}__input {
                margin-bottom: 0;
            }
        }

        &--checkbox-list {
            .checkbox {
                margin-bottom: $form-field-spacing-base;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--radio-list {
            .radio {
                margin-bottom: $form-field-spacing-base;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--checkbutton-list {
            flex-direction: row;
            gap: spacing(md);

            &--column {
                flex-direction: column;
                gap: spacing(sm);

                > * {
                    flex-grow: 1;
                    flex-basis: 0;
                }
            }

            &--dense {
                gap: spacing(2xs);
            }
        }

        &--auto {
            width: auto;
        }

        &--disabled {
            color: color(grey);

            #{$form} {
                &__input {
                    background-color: color(grey, 5);
                    border-color: color(grey, 5);
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .form {
        $form: &;

        &__field {
            &--checkbutton-list {
                &--tablet {
                    &-column {
                        flex-direction: column;
                        gap: spacing(xs);
                    }

                    &-row {
                        flex-direction: row;
                        align-items: initial;

                        > * {
                            display: flex;
                            flex: 1;
                            align-items: initial;
                        }
                    }
                }
            }
        }
    }
}
