.hidden {
    display: none !important;
}

.pointer-events-none {
    pointer-events: none;
}

.white-space-nowrap {
    white-space: nowrap;
}

.lang {
    display: none;
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.rotate {
    &--left {
        transform-origin: right center;
        transform: rotate(270deg) translate3d(0%, 0, 0);
    }
}

.width {
    &--full {
        width: 100% !important;
    }

    &--auto {
        width: auto !important;
    }
}

.z-index {
    @for $i from 0 through 20 {
        &--#{$i} {
            z-index: $i !important;
        }
    }
    @for $i from 1 through 20 {
        &--#{$i}--neg {
            z-index: -$i !important;
        }
    }

    &--top {
        z-index: 999;
    }
}

@media only screen and (min-width: map-get($breakpoints, lg)) {
    .hide {
        &--on-desktop {
            display: none !important;
        }
    }

    .show {
        &--on-desktop {
            display: block !important;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .hide {
        &--on-tablet {
            display: none !important;
        }
    }

    .show {
        &--on-tablet {
            display: block !important;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .hide {
        &--on-mobile {
            display: none !important;
        }
    }

    .show {
        &--on-mobile {
            display: block !important;
        }
    }
}

@each $breakpoint-key, $breakpoint-value in $breakpoints {
    @media (min-width: #{$breakpoint-value}) {
        .hide-above-#{$breakpoint-key} {
            display: none !important;
        }
    }
    @media (max-width: #{$breakpoint-value - 1}) {
        .hide-below-#{$breakpoint-key} {
            display: none !important;
        }
    }
}
