html {
    .vertical-align {
        &--middle {
            vertical-align: middle;
        }

        &--top {
            vertical-align: top;
        }

        &--bottom {
            vertical-align: bottom;
        }
    }

    .align-items {
        &--center {
            align-items: center;
        }

        &--flex-end {
            align-items: flex-end;
        }

        &--flex-start {
            align-items: flex-start;
        }

        &--reset {
            align-items: initial;
        }
    }

    .justify-content {
        &--center {
            justify-content: center;
        }

        &--flex-end {
            justify-content: flex-end;
        }

        &--flex-start {
            justify-content: flex-start;
        }

        &--space-between {
            justify-content: space-between;
        }

        &--space-around {
            justify-content: space-around;
        }
    }

    .align {
        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--center {
            text-align: center;
        }
    }

    .align-self {
        &--flex-end {
            align-self: flex-end;
        }

        &--flex-start {
            align-self: flex-start;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    html {
        .align {
            &-mobile {
                &--left {
                    text-align: left;
                }

                &--right {
                    text-align: right;
                }

                &--center {
                    text-align: center;
                }
            }

            &-items {
                &-mobile {
                    &--center {
                        align-items: center;
                    }

                    &--flex-end {
                        align-items: flex-end;
                    }

                    &--flex-start {
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}
