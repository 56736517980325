.link {
    $root: &;

    display: inline-flex;
    align-items: center;
    color: $link-color-base;
    cursor: pointer;
    transition: $transition-base;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    letter-spacing: $letter-spacing-md;
    gap: spacing(2xs);

    &--disabled {
        pointer-events: none;
        opacity: .3;
    }

    &--full {
        display: flex;
        width: 100%;
    }

    &:hover {
        text-decoration: underline;
    }
}
