$section-white-bg: color(white);
$section-green-bg: color(green, 2);
$section-green-color: color(grey, 7);

.section {
    padding: spacing(base) 0;
    margin-bottom: spacing(xl);

    &--clear {
        padding: 0;

        &-top {
            padding-top: 0;
        }

        &-bottom {
            padding-bottom: 0;
        }
    }

    &--white {
        background-color: $section-white-bg;
    }

    &--spaced {
        padding: spacing(3xl) 0;
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .section {
        padding: spacing(lg) 0;

        &--clear {
            padding: 0;

            &-top {
                padding-top: 0;
            }

            &-bottom {
                padding-bottom: 0;
            }
        }

        &--spaced {
            padding: spacing(2xl) 0;
        }
    }
}
