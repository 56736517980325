$headings: (
    h1: (
        desktop: $font-size-2xl,
        tablet: $font-size-2xl,
        mobile: $font-size-2xl
    ),
    h2: (
        desktop: $font-size-xl,
        tablet: $font-size-xl,
        mobile: $font-size-xl
    ),
    h3: (
        desktop: $font-size-lg,
        tablet: $font-size-lg,
        mobile: $font-size-lg
    ),
    h4: (
        desktop: $font-size-md,
        tablet: $font-size-md,
        mobile: $font-size-md
    ),
    h5: (
        desktop: $font-size-base,
        tablet: $font-size-base,
        mobile: $font-size-base
    ),
    h6: (
        desktop: $font-size-sm,
        tablet: $font-size-sm,
        mobile: $font-size-sm
    )
);

@function headingSize($size, $platform: 'desktop') {
    @return map-get(map_get($headings, $size), $platform);
}

.heading {
    display: block;
    width: 100%;
    -moz-osx-font-smoothing: auto;
    font-smoothing: auto;
    color: color(grey, 6);
    font-family: $font-family-primary;
    font-weight: $font-weight-light;
    line-height: $line-height-sm;
    margin-bottom: spacing(lg);
    position: relative;
    text-transform: uppercase;

    &--flex {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .icon {
        margin-right: spacing(xs);
    }

    &--1 {
        font-size: headingSize(h1);
        line-height: $line-height-xs;
        margin-bottom: spacing(2xl);
    }

    &--2 {
        font-size: headingSize(h2);
    }

    &--3 {
        font-size: headingSize(h3);
        color: $font-color-base;
        font-weight: $font-weight-regular;
        margin-bottom: spacing(base);
    }

    &--4 {
        font-size: headingSize(h4);
        font-style: normal;
        color: $font-color-base;
        font-weight: $font-weight-regular;
        margin-bottom: spacing(sm);
    }

    &--5 {
        font-size: headingSize(h5);
        font-weight: $font-weight-regular;
        line-height: $line-height-base;
        font-style: normal;
        color: $font-color-base;
        margin-bottom: spacing(base);
    }

    &--6 {
        font-size: headingSize(h6);
        font-style: normal;
        margin-bottom: spacing(base);
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &--no-transform {
        text-transform: none;
    }

    &--normal-style {
        font-style: normal;
    }

    &--underlined {
        display: block;
        overflow-wrap: break-word;
        inline-size: 100%;
        text-align: center;

        &:after {
            content: '';
            display: block;
            height: $border-width-bold;
            width: 50%;
            max-width: 135px;
            background: color(purple);
            margin: spacing(lg) auto 0 auto;
        }

        &--left {
            text-align: left;

            &:after {
                margin-left: 0;
            }
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, lg)) {
    .heading {
        &--1 {
            margin-bottom: spacing(2xl);
        }
    }
}
@media only screen and (max-width: map-get($breakpoints, sm)) {
    .heading {
        &--1 {
            font-size: headingSize(h1, 'mobile');
        }
        &--2 {
            font-size: headingSize(h2, 'mobile');
        }
        &--3 {
            font-size: headingSize(h3, 'mobile');
        }
        &--4 {
            font-size: headingSize(h4, 'mobile');
        }
        &--5 {
            font-size: headingSize(h5, 'mobile');
        }
        &--6 {
            font-size: headingSize(h6, 'mobile');
        }
    }
}
