.parallax-window {
    min-height: 400px;
    background: transparent;

    img {
        object-fit: cover;
    }
}

.parallax-mirror {
    img {
        object-fit: cover;
    }
}
