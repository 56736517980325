.retreats {
    $retreats: &;

    &__item {
        margin-bottom: spacing(3xl);

        &:nth-child(odd) {
            flex-direction: row-reverse;

            #{$retreats} {
                &__content {
                    padding-right: 0;
                    padding-left: spacing(3xl);
                }
            }
        }
    }

    &__content {
        padding-right: spacing(3xl);

        &-container {
            display: flex;
            align-items: center;
        }
    }

    &__image {
        .image {
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, md)) {
    .retreats {
        $retreats: &;

        &__content {
            padding: 0;
        }

        &__item {
            flex-direction: column-reverse;

            &:nth-child(odd) {
                flex-direction: column-reverse;

                #{$retreats} {
                    &__content {
                        padding: 0;
                    }
                }
            }
        }
    }
}
