.map {
    width: 100%;
    position: relative;
    padding-bottom: 40%;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    &--full {
        height: 100%;
    }
}
