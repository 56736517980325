img {
    display: block;
    max-width: 100%;
    height: auto;
}

.image {
    $image: &;

    display: flex;

    &-bg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;

        &__sources {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            img {
                max-width: 1000% !important;
                width: auto !important;
                min-width: 100%;
                height: auto;
                min-height: 100%;
                position: absolute;
            }
        }
    }

    &--full {
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--align {
        &-center {
            justify-content: center;
        }
        &-right {
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .image {
        $image: &;

        &-bg {
            &--static-on-mobile {
                position: relative;

                #{$image}-bg__sources {
                    display: block;
                    position: relative;

                    img {
                        position: relative;
                    }
                }
            }

            &--underlay-on-mobile {
                #{$image}-bg__sources {
                    display: flex;
                    position: absolute;

                    img {
                        position: absolute;
                    }
                }
            }
        }
    }
}
