.parallax {
    &-image {
        min-height: 400px;

        &--1 {
            min-height: 440px;
        }

        &--2 {
            min-height: 540px;
        }
    }

    &-mirror {
        transform: none;
        will-change: transform;
    }

    &-mask {
        backface-visibility: hidden;
        mask-repeat: no-repeat;
        mask-size: cover;

        &--hero {
            mask-image: url(#{$images-folder}hero-mask.svg);
            -webkit-mask-image: url(#{$images-folder}hero-mask.svg);
            mask-position: bottom center;
        }

        &--hero-2 {
            mask-image: url(#{$images-folder}hero-mask-2.svg);
            -webkit-mask-image: url(#{$images-folder}hero-mask-2.svg);
            mask-position: bottom center;
        }

        &--1 {
            mask-image: url(#{$images-folder}parallax-1-mask.svg);
            -webkit-mask-image: url(#{$images-folder}parallax-1-mask.svg);
        }

        &--2 {
            mask-image: url(#{$images-folder}parallax-2-mask.svg);
            -webkit-mask-image: url(#{$images-folder}parallax-2-mask.svg);
        }

        &--3 {
            mask-image: url(#{$images-folder}parallax-3-mask.svg);
            -webkit-mask-image: url(#{$images-folder}parallax-3-mask.svg);
        }

        &--4 {
            mask-image: url(#{$images-folder}parallax-1-mask.svg);
            -webkit-mask-image: url(#{$images-folder}parallax-1-mask.svg);
        }
    }
}

@media only screen and (max-width: map-get($breakpoints, sm)) {
    .parallax {
        &-mask {
            &--hero,
            &--hero-2 {
                mask-image: none;
                -webkit-mask-image: none;
            }
        }
    }
}
